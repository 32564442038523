<template>
    <div class="container">
        <div class="table-form-header" style=" text-align: center; padding-bottom:10px; font-size: 20px;">
            <h3>基本信息采集表</h3>
        </div>
        <div class="header-button mb-1">
            <el-button @click="saveInfoCollect(isEditing)" type="primary" size="small">
                {{ isEditing ? '保存个人信息' : '编辑个人信息' }}
            </el-button>
        </div>
        <el-form ref="form" :model="formData" :rules='rules'>
            <table class="table" border="1"
                style=" width: 100%; border-collapse: collapse; table-layout: fixed;font-size:15px;">
                <tr>
                    <td class="font-right">教职工号: </td>
                    <td>
                        <!-- <el-input v-if="isEditing" maxlength="20" v-model="formData.teacherNo"
                            placeholder="请输入内容"></el-input> -->
                        <span>{{ formData.teacherNo }}</span>

                    </td>
                    <td rowspan="5" class="font-right">照片预览:</td>
                    <td rowspan="5">
                        <el-image :src="`${$fileUrl}${formData.photo}`" style="width: 120px;"></el-image>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">姓名: </td>
                    <td>
                        <span>{{ formData.teacherName }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">性别:</td>
                    <td>
                        <span>{{ formData.sexType == '1' ? '男' : '女' }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">民族: </td>
                    <td>
                        <el-select v-if="isEditing" v-model="formData.nation" placeholder="请选择">
                            <el-option v-for="item in nationList" :key="item.id" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                        <span v-else>{{ formData.nation }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">出生日期: </td>
                    <td>
                        <el-date-picker v-if="isEditing" value-format="yyyy-MM-dd" v-model="formData.birthday" type="date"
                            placeholder="选择日期">
                        </el-date-picker>
                        <span v-else>{{ formData.birthday }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">身份证号: </td>
                    <td>
                        <span>{{ formData.idNumber }}</span>
                    </td>
                    <td class="font-right">所在部门/院系:</td>
                    <td>
                        <span>{{ formData.deptName }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">籍贯: </td>
                    <td>
                        <el-input v-if="isEditing" maxlength="10" v-model="formData.nativePlace"
                            placeholder="请输入内容"></el-input>
                        <span v-else>{{ formData.nativePlace }}</span>
                    </td>
                    <td class="font-right">职工类别:</td>
                    <td>
                        <span>{{ formData.teacherType }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">政治面貌:</td>
                    <td>
                        <el-select v-if="isEditing" v-model="formData.policyStatus" placeholder="请选择">
                            <el-option v-for="(item, index) in policyStatusList" :key="index" :label="item.type"
                                :value="item.type">
                            </el-option>
                        </el-select>
                        <span v-else>{{ formData.policyStatus }}</span>
                    </td>
                    <td class="font-right">是否骨干教师:</td>
                    <td>
                        <el-select v-if="isEditing" v-model="formData.coreFlag" placeholder="请选择">
                            <el-option v-for="item in isCoreFlag" :key="item.type" :label="item.name" :value="item.type">
                            </el-option>
                        </el-select>
                        <span v-else>{{ formData.coreFlag == 1 ? '是' : '否' }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">进本校年月: </td>
                    <td>
                        <el-date-picker v-if="isEditing" value-format="yyyy-MM-dd" v-model="formData.joinSchoolDay"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                        <span v-else>{{ formData.joinSchoolDay }}</span>
                    </td>
                    <td class="font-right">是否双师型教师:</td>
                    <td>
                        <el-select v-if="isEditing" v-model="formData.doubleTeacherFlag" placeholder="请选择">
                            <el-option v-for="item in isCoreFlag" :key="item.type" :label="item.name" :value="item.type">
                            </el-option>
                        </el-select>
                        <span v-else>{{ formData.doubleTeacherFlag == 1 ? '是' : '否' }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">学历:</td>
                    <td>
                        <el-select v-if="isEditing" v-model="formData.education" placeholder="请选择">
                            <el-option v-for="(item, index) in educationList" :key="index" :label="item.type"
                                :value="item.type">
                            </el-option>
                        </el-select>
                        <span v-else>{{ formData.education }}</span>
                    </td>
                    <td class="font-right">职称:</td>
                    <td>
                        <el-input maxlength="50" v-if="isEditing" v-model="formData.postName"
                            placeholder="请输入内容"></el-input>
                        <span v-else>{{ formData.postName }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">婚姻状况:</td>
                    <td>
                        <el-select v-if="isEditing" v-model="formData.maritalStatus" placeholder="请选择">
                            <el-option v-for="item in maritalStatusList" :key="item.type" :label="item.name"
                                :value="item.type">
                            </el-option>
                        </el-select>
                        <span v-else>{{ formData.maritalStatus }}</span>
                    </td>
                    <td class="font-right">有无教师职称证:</td>
                    <td>
                        <el-select v-if="isEditing" v-model="formData.teacherCertFlag" placeholder="请选择">
                            <el-option v-for="item in isCoreFlag" :key="item.type" :label="item.name" :value="item.type">
                            </el-option>
                        </el-select>
                        <span v-else>{{ formData.teacherCertFlag === 1 ? '是' : '否' }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">参加工作年月:</td>
                    <td>
                        <el-date-picker v-if="isEditing" value-format="yyyy-MM-dd"
                            v-model="formData.workday"></el-date-picker>
                        <span v-else>{{ formData.workday }}</span>
                    </td>
                    <td class="font-right">职业资格证书:</td>
                    <td>
                        <el-input maxlength="100" v-if="isEditing" v-model="formData.jobCert"
                            placeholder="请输入内容"></el-input>
                        <span v-else>{{ formData.jobCert }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">家庭住址:</td>
                    <td colspan="3">
                        <el-input maxlength="100" v-if="isEditing" v-model="formData.address"
                            placeholder="请输入内容"></el-input>
                        <span v-else>{{ formData.address }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="font-right">工作经历:</td>
                    <td colspan="3">
                        <el-input maxlength="200" v-if="isEditing" v-model="formData.workHistory"
                            placeholder="请输入内容"></el-input>
                        <span v-else>{{ formData.workHistory }}</span>
                    </td>
                </tr>
            </table>
        </el-form>
    </div>
</template>

<script>
import { getInfoCollectByUserId, saveInfoCollect } from '@/api/infoCollect/infoCollect-api.js'
import { getParamsList } from '@/api/sysParams.js'
import { sexType, isCoreFlag, isDoubleTeacherFlag, isTeacherCertFlag, maritalStatusList, policyStatusList, educationList, nationList } from './components/options.js'

export default {
    data() {
        return {
            formData: {
            },
            sexType,
            isCoreFlag,
            isDoubleTeacherFlag,
            isTeacherCertFlag,
            maritalStatusList,
            policyStatusList,
            educationList,
            nationList,
            teacherTypeList: [],
            isEditing: false,
            rules: {
                teacherName: [this.$store.state.commonValidate.limit20WordsObj],
                nation: [this.$store.state.commonValidate.limit20WordsObj],
                teacherNo: [this.$store.state.commonValidate.validatorNumberObj]
            }

        }
    },
    mounted() {
        this.getInfoCollectById()
        this.getTeacherType()
    },
    methods: {
        // 保存表单信息
        saveInfoCollect(nowIsEditing) {
            this.isEditing = !this.isEditing
            if (nowIsEditing) {
                saveInfoCollect(this.formData)
            }
        },
        // 获取表单信息
        getInfoCollectById() {
            const userId = this.$store.state.login.userInfo.id
            getInfoCollectByUserId(userId).then((res) => {
                this.formData = res.data
            })
        },
        // 获取职工类型
        getTeacherType() {
            getParamsList({ keyword: '教师类型' }).then((res) => {
                this.teacherTypeList = res.data.list
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px !important;
    background: #fff;

    td {
        padding: 5px;
        line-height: 40px;
    }

    .font-right {
        width: 200px;
        background: #bddaf7;
    }
}
</style>
